<nav class="mb-10 md:mb-0 mt-10 w-full text-white">
  <ul class="flex items-center justify-center gap-2">
    <li>
      <button
        (click)="handlePageChange(1)"
        class="rounded-md px-3 py-1.5 lg:hover:bg-orange-400 lg:hover:text-black transition-all"
        [ngClass]="{ 'pointer-events-none opacity-50': currentPage === 1 }"
      >
        <i class="fas fa-angle-double-left"></i>
      </button>
    </li>

    <li>
      <button
        (click)="handlePageChange('prev')"
        class="rounded-md px-3 py-1.5 lg:hover:bg-orange-400 lg:hover:text-black transition-all"
        [ngClass]="{
          'pointer-events-none opacity-50': currentPage === 1
        }"
      >
        <i class="fas fa-angle-left"></i>
      </button>
    </li>
    <li class="flex md:hidden">
      <select
        #pageSelect
        class="rounded-md border px-2 border-orange-400 bg-transparent h-10 cursor-pointer text-orange-400 text-center"
        [(ngModel)]="currentPage"
        (change)="handlePageChange(+pageSelect.value)"
      >
        <option *ngFor="let page of selectPageNums">
          {{ page }}
        </option>
      </select>
    </li>
    <li class="hidden md:flex" *ngFor="let page of getPagesInRange()">
      <button
        (click)="handlePageChange(page)"
        class="rounded-md px-3 py-1.5 border border-orange-400 hover:bg-orange-400 hover:text-black transition-all w-fit"
        [ngClass]="{ 'bg-orange-400 text-black': page === currentPage }"
      >
        {{ page }}
      </button>
    </li>
    <li>
      <button
        (click)="handlePageChange('next')"
        class="rounded-md px-3 py-1.5 lg:hover:bg-orange-400 lg:hover:text-black transition-all"
        [ngClass]="{
          'pointer-events-none opacity-50': currentPage === totalPages
        }"
      >
        <i class="fas fa-angle-right"></i>
      </button>
    </li>

    <li>
      <button
        (click)="handlePageChange(totalPages)"
        class="rounded-md px-3 py-1.5 lg:hover:bg-orange-400 lg:hover:text-black transition-all"
        [ngClass]="{
          'pointer-events-none opacity-50': currentPage === totalPages
        }"
      >
        <i class="fas fa-angle-double-right"></i>
      </button>
    </li>
  </ul>
</nav>
