export const blocksExamples: string[] = [
  '00000000000000000002dc97aa53e4e099eee81b677d838449c3f00284703b78',
  '0000000000000000000264e8310465ef4f5ad2c6d45dd374244b815b083ac413',
  '000000000000000000013afd0c7dd83597a90c052d70e8646018a05e5179965f',
  '00000000000000000002cb03806a794a54dbf03dcae17f5f26bb335f978ef841',
  '0000000000000000000208ac2facb1b6c86a8624a38a35f2e996777df6a34d8a',
  '000000000000000000016b4a954f26d3745a67de8e6828b9ff1754a999bad305',
  '000000000000000000001fa538eafdb59423e55e214b25578c553e71a49c611a',
  '00000000000000000002fa4e427b559b5cdfd9414855d6836ab982e70faaf1c2',
  '000000000000000000028ddefd0c0d83451d20573bbb8ef6ab421a7c985a588f',
  '00000000000000000002db892383a773f7a9feee4f8df800c46a77a0b53e7c97',
  '00000000000000000001171b47c1c39541fd85d16ae0dbfee83f26c847515703',
  '000000000000000000030bfb48d3b9d033defd1b68d386635c46893bf4dabb92',
  '0000000000000000000093072557eb120911f2e9ad8741f09c6402807bd93070',
  '0000000000000000000213bc18d7e852578fa68d53896635a2416577febb612a',
  '000000000000000000028c7f0bd1b125c2b340672a4941a618c1291ecb4ac238',
  '000000000000000000010df706f4477ccec04eff86d9eb3f5686a257891e73f8',
  '0000000000000000000229547a851710b67ae005d73ad1c5a734863d6b61b466',
  '753201',
  '782042',
  '718931',
  '796522',
  '761473',
  '735894',
  '799991',
  '712341',
  '789002',
  '775431',
  '665431',
  '680211',
  '692341',
  '675001',
  '610121',
  '690011',
  '675431',
  '667891',
  '698761',
  '695431',
  '849881',
  '832101',
  '823421',
  '803451',
  '837611',
  '818971',
  '802341',
  '845171',
  '830021',
  '814561',
];
