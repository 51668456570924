<div
  class="max-w-screen-lg mx-6 lg:mx-auto py-6 px-8 mb-8 md:py-8 md:px-10 flex flex-col md:flex-row items-center justify-center bor-gr-anim"
>
  <div class="text-center md:text-left md:mr-6 mb-4 md:mb-0 md:ml-6">
    <h2 class="font-bold text-3xl md:text-4xl mb-2 fade-col">
      Bitcoin
      <span class="font-semibold fade-col-rev">insights</span>
    </h2>
    <p class="text-gray-400">
      Discover the world of Bitcoin.
      <span class="hidden md:inline"
        >Explore BTC addresses, transactions, and blocks.</span
      >
    </p>
  </div>

  <div class="text-center">
    <img src="/assets/mascot.png" alt="" class="w-32 h-auto md:ml-4" />
  </div>
</div>

<div class="flex flex-col justify-center items-center">
  <div class="relative">
    <form (ngSubmit)="onSearch(searchInput.value)" class="relative">
      <input
        #searchInput
        type="search"
        name="search"
        placeholder="search for address, transaction, or block"
        class="main-search bg-gray-800 text-xs text-white h-10 md:h-12 px-4 pr-10 rounded-lg rounded-b-md focus:outline-none w-[300px] sm:w-[380px] md:w-[650px] border-b-2 focus:border-orange-400 transition-all duration-300 ease-in-out mb-3 lg:mb-0"
        autocomplete="off"
      />

      <button
        type="submit"
        class="absolute right-0 -top-1.5 lg:top-0 h-full w-10 flex items-center justify-center"
      >
        <svg
          class="h-4 w-4 md:h-5 md:w-5 text-white lg:hover:text-orange-400 duration-200"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          viewBox="0 0 56.966 56.966"
          fill="currentColor"
        >
          <path
            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
          />
        </svg>
      </button>
    </form>
  </div>

  <div class="flex flex-wrap justify-center lg:mt-5 text-sm">
    <button
      class="cursor-pointer rounded-lg px-3 py-1 text-white transition-colors duration-300 border border-orange-400 bg-transparent mt-3 mx-1.5 md:my-1 lg:flex-initial lg:hover:bg-gray-800 hover:border-white"
      (click)="onExampleClick('address')"
    >
      address
    </button>
    <button
      class="cursor-pointer rounded-lg px-3 py-1 text-white transition-colors duration-300 border border-orange-400 bg-transparent mt-3 mx-1.5 md:my-1 lg:flex-initial lg:hover:bg-gray-800 hover:border-white"
      (click)="onExampleClick('block')"
    >
      block
    </button>
    <button
      class="cursor-pointer rounded-lg px-3 py-1 text-white transition-colors duration-300 border border-orange-400 bg-transparent mt-3 mx-1.5 md:my-1 lg:flex-initial lg:hover:bg-gray-800 hover:border-white"
      (click)="onExampleClick('transaction')"
    >
      transaction
    </button>
  </div>

  <div class="mt-8">
    <ul
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-6 text-white w-[320px] sm:w-full mb-10 lg:mb-0"
    >
      <li
        class="border border-gray-700 rounded-3xl p-3 md:p-6 text-center shadow-sm shadow-white md:shadow-inner md:shadow-white lg:hover:scale-105 lg:transform lg:transition-transform lg:duration-500 text-sm"
      >
        <div class="flex items-center justify-center mb-2">
          <i class="fa-brands fa-bitcoin mr-2 text-xl lg:text-2xl"></i>
          <p class="mr-2">Bitcoin</p>
          <p class="text-gray-400">BTC</p>
        </div>

        <ng-container *ngIf="btcPrice; else spinner">
          <div class="flex justify-center">
            <p class="font-medium mr-1">{{ btcPrice | number : "1.2-2" }}</p>
            <p class="text-gray-400">USD</p>
          </div>
        </ng-container>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </li>

      <li
        class="border border-gray-700 rounded-3xl p-3 md:p-6 text-center shadow-sm shadow-white md:shadow-inner md:shadow-white lg:hover:scale-105 lg:transform lg:transition-transform lg:duration-500 text-sm"
      >
        <div class="flex items-center justify-center mb-2">
          <i class="fa-solid fa-cubes mr-2 text-xl lg:text-2xl"></i>
          <p class="text-gray-400">Blocks</p>
        </div>

        <ng-container
          *ngIf="
            blockchainInfo && blockchainInfo.blocks !== undefined;
            else spinner
          "
        >
          <p>{{ blockchainInfo.blocks }}</p>
        </ng-container>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </li>

      <li
        class="border border-gray-700 rounded-3xl p-3 md:p-6 text-center shadow-sm shadow-white md:shadow-inner md:shadow-white lg:hover:scale-105 lg:transform lg:transition-transform lg:duration-500 text-sm"
      >
        <div class="flex items-center justify-center mb-2">
          <i class="fa-solid fa-chain mr-2 text-xl lg:text-2xl"></i>
          <p class="text-gray-400">Chain</p>
        </div>

        <ng-container
          *ngIf="
            blockchainInfo && blockchainInfo.chain !== undefined;
            else spinner
          "
        >
          <p>{{ blockchainInfo.chain }}</p>
        </ng-container>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </li>

      <li
        class="border border-gray-700 rounded-3xl p-3 md:p-6 text-center shadow-white shadow-sm md:shadow-md md:shadow-white lg:hover:scale-105 lg:transform lg:transition-transform lg:duration-500 text-sm"
      >
        <div class="flex items-center justify-center mb-2">
          <i class="fa-solid fa-chart-line mr-2 text-xl lg:text-2xl"></i>
          <p class="text-gray-400">Difficulty</p>
        </div>

        <ng-container
          *ngIf="
            blockchainInfo && blockchainInfo.difficulty !== undefined;
            else spinner
          "
        >
          <div class="flex justify-center">
            <p class="mr-1">
              {{ blockchainInfo.difficulty | difficultyConversion }}
            </p>
            <p class="text-gray-400">T</p>
          </div>
        </ng-container>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </li>

      <li
        class="border border-gray-700 rounded-3xl p-3 md:p-6 text-center shadow-white shadow-sm md:shadow-md md:shadow-white lg:hover:scale-105 lg:transform lg:transition-transform lg:duration-500 text-sm"
      >
        <div class="flex items-center justify-center mb-2">
          <i class="fa-solid fa-network-wired mr-2 text-xl lg:text-2xl"></i>
          <p class="text-gray-400">Network Hash Rate</p>
        </div>

        <ng-container
          *ngIf="
            blockchainInfo && blockchainInfo.networkHashPerSecond !== undefined;
            else spinner
          "
        >
          <div class="flex justify-center">
            <p class="mr-1">
              {{ blockchainInfo.networkHashPerSecond | hashRateConversion }}
            </p>
            <p class="text-gray-400">EH/s</p>
          </div>
        </ng-container>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </li>

      <li
        class="border border-gray-700 rounded-3xl p-3 md:p-6 text-center shadow-white shadow-sm md:shadow-md md:shadow-white lg:hover:scale-105 lg:transform lg:transition-transform lg:duration-500 text-sm"
      >
        <div class="flex items-center justify-center mb-2">
          <i class="fa-solid fa-coins mr-2 text-xl lg:text-2xl"></i>
          <p class="text-gray-400">Pooled Transactions</p>
        </div>

        <ng-container
          *ngIf="
            blockchainInfo && blockchainInfo.pooledTx !== undefined;
            else spinner
          "
        >
          <p>{{ blockchainInfo.pooledTx }}</p>
        </ng-container>

        <ng-template #spinner>
          <app-spinner></app-spinner>
        </ng-template>
      </li>
    </ul>
  </div>

  <!--! latest blocks -->
  <ng-container>
    <div class="bg-transparent rounded-lg p-4 mt-12 hidden lg:block border">
      <div class="w-[860px]">
        <div
          class="flex justify-start items-center text-left text-xl md:text-2xl space-x-2 mb-4 pt-2 ml-6"
          [ngClass]="{ 'animate-pulse': latestBlocks.length === 0 }"
        >
          <h2 class="font-semibold text-white">Latest</h2>
          <i class="fas fa-cube text-orange-500"></i>
          <p class="font-medium text-orange-500">blocks</p>
        </div>
      </div>

      <table
        class="w-full divide-y divide-gray-200"
        *ngIf="latestBlocks.length > 0"
      >
        <thead
          class="text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
        >
          <tr>
            <th class="px-8 py-3">Height</th>
            <th class="px-8 py-3 pl-9">Time (EEST)</th>
            <th class="px-8 py-3">Transactions</th>
            <th class="px-8 py-3 pl-9">Size (KB)</th>
            <th class="px-8 py-3 pl-10">Weight (KWU)</th>
          </tr>
        </thead>

        <tbody class="text-white divide-y divide-gray-600">
          <tr *ngFor="let block of latestBlocks" class="text-center">
            <td class="px-8 py-4">
              <a
                routerLink="/insights/block/{{ block.blockHeight }}"
                class="text-orange-400 hover:text-orange-300 transition-colors duration-300"
                >{{ block.blockHeight }}</a
              >
            </td>
            <td class="px-8 py-4">
              {{ +block.time * 1000 | date : "MMM dd, yyyy, HH:mm:ss" }}
            </td>
            <td class="px-8 py-4">{{ block.ntx }}</td>
            <td class="px-8 py-4">
              {{ trimTrailingZeros((block.size / 1000).toFixed(3)) }}
            </td>
            <td class="px-8 py-4">
              {{ trimTrailingZeros((block.weight / 1000).toFixed(3)) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container>
    <div
      class="bg-transparent rounded-lg p-4 lg:hidden w-[330px] sm:w-[500px] md:w-full"
    >
      <div
        class="flex justify-center items-center text-xl md:text-2xl space-x-2 mb-4"
        [ngClass]="{ 'animate-bounce': latestBlocks.length === 0 }"
      >
        <h2 class="font-semibold text-white">Latest</h2>
        <i class="fas fa-cube text-orange-500"></i>
        <p class="font-medium text-orange-500">blocks</p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:mx-10">
        <ng-container *ngFor="let block of latestBlocks">
          <div class="bg-gray-800 rounded-lg p-4 flex">
            <div class="text-white text-center w-1/2">
              <h3 class="text-lg font-semibold my-1">
                <a
                  routerLink="/insights/block/{{ block.blockHeight }}"
                  class="text-orange-400 hover:text-orange-300 transition-colors duration-300"
                >
                  {{ block.blockHeight }}
                </a>
              </h3>
              <p class="font-medium px-4">
                {{ +block.time * 1000 | date : "dd/MM/yy, HH:mm:ss" }}
              </p>
            </div>
            <div class="w-1/2 flex justify-center items-center mt-1">
              <div class="text-center text-white">
                <div class="flex">
                  <p class="font-medium text-white space-x-1">
                    <span class="text-white">{{ block.ntx }}</span>
                    <span class="text-gray-400 text-xs">TXs</span>
                  </p>
                </div>
                <div class="flex">
                  <p class="font-medium text-white space-x-1">
                    <span class="text-white">{{
                      trimTrailingZeros((block.size / 1000).toFixed(3))
                    }}</span>
                    <span class="text-gray-400 text-xs">KB</span>
                  </p>
                </div>
                <div class="flex">
                  <p class="font-medium text-white space-x-1">
                    <span class="text-white">{{
                      trimTrailingZeros((block.weight / 1000).toFixed(3))
                    }}</span>
                    <span class="text-gray-400 text-xs">KWU</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!--! latest transactions -->
  <ng-container>
    <div class="bg-transparent rounded-lg p-4 lg:mt-6 hidden lg:block border">
      <div class="w-[860px]">
        <div
          class="flex justify-start items-center text-left text-xl md:text-2xl space-x-2 mb-4 pt-2 ml-6"
          [ngClass]="{ 'animate-pulse': latestTransactions.length === 0 }"
        >
          <h2 class="font-semibold text-white">Latest</h2>
          <i class="fas fa-exchange-alt text-orange-500"></i>
          <p class="font-medium text-orange-500">transactions</p>
        </div>
      </div>

      <table
        class="w-full divide-y divide-gray-200"
        *ngIf="latestTransactions.length > 0"
      >
        <thead
          class="text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
        >
          <tr>
            <th class="px-8 py-3">Transaction ID</th>
            <th class="px-8 py-3">Amount (BTC)</th>
            <th class="px-8 py-3 pl-10">Size (B)</th>
            <th class="px-8 py-3 pl-9">Time (EEST)</th>
          </tr>
        </thead>
        <tbody class="text-white divide-y divide-gray-600">
          <tr *ngFor="let tx of latestTransactions" class="text-center">
            <td class="px-8 py-4">
              <div class="flex items-center justify-between">
                <a
                  routerLink="/insights/transaction/{{ tx.txId }}"
                  class="text-orange-400 hover:text-orange-300 transition-colors duration-300"
                  >{{ tx.txId | truncate }}</a
                >
                <!-- ! to be reviewed and refactored -->
                <button
                  ngxClipboard
                  [cbContent]="tx.txId"
                  (cbOnSuccess)="showTooltip(tx.txId)"
                  (mouseenter)="showCopy(tx.txId)"
                  (mouseleave)="hideTooltip()"
                  class="relative"
                >
                  <img
                    src="/assets/copy.svg"
                    class="w-5 h-5 ml-2"
                    [ngClass]="{ 'animate-bounce': animatedIndex === tx.txId }"
                    alt=""
                  />
                  <span
                    *ngIf="showTooltipId === tx.txId"
                    class="absolute top-0 left-0 mt-6 ml-2 px-2 py-1 bg-gray-800 text-white rounded whitespace-nowrap opacity-100 transition-opacity duration-200"
                  >
                    {{ showCopied ? "copied!" : "copy" }}
                  </span>
                </button>
              </div>
            </td>
            <td class="px-8 py-4 flex items-center justify-between">
              {{ calculateTotalValue(tx) | fixedLengthNumber : 8 }}
              <img class="w-5 h-5 ml-2" src="/assets/white-btc.png" alt="" />
            </td>
            <td class="px-8 py-4">{{ tx.size }}</td>
            <td class="px-8 py-4">
              {{ +tx.time * 1000 | date : "MMM dd, yyyy, HH:mm:ss" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container>
    <div
      class="bg-transparent rounded-lg p-4 lg:hidden w-[330px] sm:w-[500px] md:w-full my-6"
    >
      <div
        class="flex justify-center items-center text-xl md:text-2xl space-x-2 mb-4"
        [ngClass]="{ 'animate-bounce': latestTransactions.length === 0 }"
      >
        <h2 class="font-semibold text-white">Latest</h2>
        <i class="fas fa-exchange-alt text-orange-500"></i>
        <p class="font-medium text-orange-500">transactions</p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:mx-10">
        <ng-container *ngFor="let tx of latestTransactions">
          <div class="bg-gray-800 rounded-lg p-4 flex">
            <div class="text-white w-full">
              <div class="text-center mb-2">
                <h3 class="text-lg font-medium">
                  <a
                    routerLink="/insights/transaction/{{ tx.txId }}"
                    class="text-orange-400 hover:text-orange-300 transition-colors duration-300"
                  >
                    {{ tx.txId | truncate }}
                  </a>
                </h3>
              </div>
              <div class="flex justify-between items-start px-6">
                <div class="text-left">
                  <p class="font-medium">
                    {{ calculateTotalValue(tx).toFixed(8) }}
                    <span class="text-gray-400 text-xs">BTC</span>
                  </p>
                  <p class="font-medium">
                    {{ +tx.time * 1000 | date : "dd/MM/yy, HH:mm:ss" }}
                  </p>
                </div>
                <div class="flex flex-col items-center">
                  <button
                    ngxClipboard
                    [cbContent]="tx.txId"
                    (cbOnSuccess)="showTooltip(tx.txId)"
                    (mouseenter)="showCopy(tx.txId)"
                    (mouseleave)="hideTooltip()"
                    class="relative mb-2"
                  >
                    <img
                      src="/assets/copy.svg"
                      class="w-5 h-5"
                      [ngClass]="{
                        'animate-bounce': animatedIndex === tx.txId
                      }"
                      alt="copy"
                    />
                    <span
                      *ngIf="showTooltipId === tx.txId"
                      class="absolute top-0 -left-8 mt-6 ml-2 px-2 py-1 bg-gray-800 text-white rounded whitespace-nowrap opacity-100 transition-opacity duration-200"
                    >
                      {{ showCopied ? "copied!" : "copy" }}
                    </span>
                  </button>
                  <p class="font-medium text-white">
                    {{ tx.size }} <span class="text-gray-400 text-xs">B</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
