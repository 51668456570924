<header class="z-50 mb-8 flex justify-center">
  <nav class="flex items-center justify-between max-w-[2000px] w-full mx-4">
    <a routerLink="/" class="relative custom-z-ind">
      <img
        class="h-16 md:h-20 w-auto"
        src="/assets/main-logo.png"
        alt="header-logo"
      />
    </a>

    <button type="button" (click)="openMobileMenu()">
      <svg
        class="h-6 w-6 md:h-8 md:w-8 text-white transition-colors duration-200 ease-in-out lg:hover:text-orange-400"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </button>
  </nav>

  <app-mobile-menu
    class="block max-menu-w:hidden menu-animation overflow-hidden md:overflow-visible"
    *ngIf="isMobileMenuOpen"
    (isMobileMenuOpenChange)="handleCloseMobileMenu($event)"
  ></app-mobile-menu>
</header>
