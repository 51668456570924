<header>
  <div class="z-50" role="dialog">
    <div
      *ngIf="isMobileMenuOpenChange"
      class="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-950 z-50"
    ></div>
    <div
      (click)="closeMobileMenu()"
      (keyup.tab)="closeMobileMenu()"
      class="fixed inset-0 z-50"
      role="button"
      tabindex="0"
    ></div>
    <div
      class="fixed inset-y-0 right-0 md:top-2 md:right-[0.2rem] z-50 w-full md:w-64 h-fit overflow-hidden bg-gray-800 px-6 py-3 md:rounded-2xl border-b md:border md:border-white"
    >
      <div class="flex items-center justify-between">
        <a
          routerLink="/"
          class="mb-14"
          (click)="closeMobileMenu()"
          *ngIf="showLogo"
        >
          <img
            class="absolute left-[18px] top-1 h-14 w-auto"
            src="/assets/main-logo.png"
            alt="mobile-logo"
          />
        </a>
        <button
          type="button"
          aria-controls="mobile-menu"
          class="bg-transparent lg:hover:bg-gray-200 lg:hover:text-gray-950 text-white rounded-md text-sm w-8 h-8 absolute top-4 right-3 inline-flex items-center justify-center"
          (click)="closeMobileMenu()"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
      <div
        class="flow-root"
        [ngClass]="{ 'mt-8': showLogo, 'mt-24': !showLogo }"
      >
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 pb-6">
            <ng-container *ngFor="let link of navLinks">
              <a
                *ngIf="!link.external"
                [routerLink]="link.route"
                (click)="closeMobileMenu()"
                class="-mx-1 md:-mx-3 block rounded-md px-3 py-2 text-xs md:text-sm font-medium leading-7 text-white hover:bg-gray-200 hover:text-gray-800 border border-gray-700 transition-all duration-300"
              >
                {{ link.label }}
              </a>

              <a
                *ngIf="link.external"
                (click)="closeMobileMenu()"
                [href]="link.route"
                [attr.target]="'_blank'"
                [attr.rel]="'noopener noreferrer'"
                class="-mx-1 md:-mx-3 block rounded-md px-3 py-2 text-xs md:text-sm font-medium leading-7 text-white hover:bg-gray-200 hover:text-gray-800 border border-gray-700 transition-all duration-300"
              >
                {{ link.label }}
                <ng-container *ngIf="link.icon">
                  <i [class]="link.icon + ' ml-1'"></i>
                </ng-container>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
